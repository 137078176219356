import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Pin Input component typically consists of a series of input fields, each representing a single digit of the PIN. The fields are arranged horizontally or vertically, depending on the design. Each input field is usually represented by a box or a circle where users can enter a digit.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-anatomy.svg",
      "alt": "anatomy navbar"
    }}></img>
    <ol>
      <li parentName="ol">{`Input Fields: Individual input fields where users enter digits of the PIN.`}</li>
      <li parentName="ol">{`Security Masking: The ability to hide or mask the entered digits, typically with asterisks (`}{`*`}{`).`}</li>
      <li parentName="ol">{`Error Indicators: Visual cues to highlight input errors, such as red borders or error messages.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Pin input components can be used in a variety of contexts, such as:`}</p>
    <ul>
      <li parentName="ul">{`Login forms`}</li>
      <li parentName="ul">{`Two-factor authentication screens`}</li>
      <li parentName="ul">{`OTP verification screens`}</li>
      <li parentName="ul">{`Credit card PIN entry screens`}</li>
    </ul>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        When you need to collect a short sequence of digits from a user, such as
        for authentication or authorization purposes.
      </li>
      <li>
        When you need to protect the user's privacy and security by hiding their
        PIN as they are entering it.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        When you need to collect a long sequence of digits from a user, such as
        a phone number or social security number.
      </li>
      <li>When you need to collect alphanumeric characters from a user.</li>
    </ul>
  </div>
    </div>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Limit the Pin Input to 6 digits to mitigate cognitive biases related to memory and recall." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-usage-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Too much digits will make user have too much cognitive load." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-usage-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`To ensure that the Pin Input component is accessible to all users, consider the following guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Make sure that the pin input component fully navigable and operable using a keyboard.`}</li>
      <li parentName="ul">{`Ensure screen readers can interpret and announce the input fields and errors accurately.`}</li>
      <li parentName="ul">{`Maintain a clear focus indicator for each input field to help keyboard users.`}</li>
      <li parentName="ul">{`Provide proper ARIA labels to assist screen reader users in understanding the purpose of the Pin Input.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Prioritize user data security by masking the entered digits to prevent onlookers from accessing sensitive information." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Never display the entered digits in plaintext; always mask or hide them for security." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Visual cues to highlight input errors, such as red borders or error messages." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-a11y-do-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="No visual cues to highlight input errors" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-a11y-dont-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When integrating the Pin Input component, consider the following content guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Clear and concise label or title to provide context for the Pin Input.`}</li>
      <li parentName="ul">{`Placeholders in the input fields to indicate the expected format, e.g., “Enter your 6-digit PIN.”`}</li>
      <li parentName="ul">{`Clear and informative error messages that guide users in resolving issues.`}</li>
      <li parentName="ul">{`Secure masked to protect user input.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Provide clear feedback on the number of incorrect digits entered" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Avoid fot not using any feedback at all regarding digit input errors" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/guideline-pin-input-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      